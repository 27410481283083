import type { WritableComputedRef } from 'vue'
import MiniSearch, {
  type Options as MiniSearchOptions,
  type SearchOptions,
  type SearchResult,
  type Suggestion,
} from 'minisearch'
import type {
  SearchMode,
  RefinerTag,
  RefinerPack,
  PopulatedInstrument,
  SearchCatalogSelectedRefiners,
  RefinerListItem,
  RefinerList,
} from '~~/types'
import type { cat_ITag, trpcRouterInput, SearchIndexItem } from '~~/server/types'

// eslint-disable-next-line import/order
import {
  useRuntimeConfig,
  useNuxtApp,
  ref,
  computed,
  watch,
  useUserStore,
  navigateTo,
  getSelectPriceRanges,
  useSearchCatalogIndex,
  useMarkJS,
} from '#imports'

export const useMinisearchInstruments = (
  options: {
    relatedVideos?: boolean
    useSearchQueryUrl?: boolean
  } = {
    relatedVideos: true,
    useSearchQueryUrl: false,
  },
) => {
  /**
   * CONFIG
   */
  const config = useRuntimeConfig()
  const SEARCH_TEXT_MIN_LENGTH = config.public.search.searchTextMinLength || 2
  const MAX_SUGGESTIONS = config.public.search.maxSuggestions || 10
  const DRUMKIT_TAG_ID = '6482efdebde9918dec7cd8d5'
  const ITEMS_PER_PAGE = config.public.search.paginatedItemsPerPage || 10
  const userStore = useUserStore()

  let miniSearch: MiniSearch<SearchIndexItem> // object with methods can not be a ref

  const searchOptions: SearchOptions = {
    // Boost some fields
    boost: {
      na: 3, // name
      de: 2, // description
      cr: 1, // creator
      ca: 1, // category
      at: 1, // attributes
      mo: 1, // moods
      st: 1, // styles
      pst: 1, // parentStyles
      pa: 1, // pack
      vi: 1, // videos
    },
    // Prefix search (so that 'moto' will match 'motorcycle')
    prefix: true,
    // Fuzzy search, in this example, with a max edit distance of 0.2 * term length,
    fuzzy: 0.2, // disable fuzzy for testing
  }

  const suggestionOptions: SearchOptions = {
    // overide of searchOptions for suggestions
    fields: [
      'na', // name
      'de', // description
      'cr', // creator
      'ca', // category
      // 'at', // attributes
      // 'mo', // moods
      'st', // styles
      'pst', // parentStyles
      'pa', // pack
      // 'vi', // videos
    ],
    boost: {
      na: 3, // name
      de: 2, // description
      cr: 1, // creator
      ca: 1, // category
      // at: 1, // attributes
      // mo: 1, // moods
      st: 1, // styles
      pst: 1, // parentStyles
      pa: 1, // pack
      // vi: 1, // videos
    },
    fuzzy: 0.2,
  }

  const minisearchOptions: MiniSearchOptions = {
    // fields to index for full-text search
    fields: [
      'na', // name
      'de', // description
      'cr', // creator
      'ca', // category
      'at', // attributes
      'mo', // moods
      'st', // styles
      'pst', // parentStyles
      'pa', // pack
      'vi', // videos
    ],
    // fields to return with search results
    storeFields: [
      'ti', // tag_ids
      'pti', // parent_tag_ids
      'pi', // pack_id
      'pr', // price
    ],
    searchOptions,
  }

  const { $trpc } = useNuxtApp()

  /**
   * initStore
   */
  const searchCatalogIndex = useSearchCatalogIndex()
  const mode = ref<SearchMode>('site')
  const tags = ref<RefinerTag[]>([])
  const packs = ref<RefinerPack[]>([])
  const currentTime = ref<number>(0)
  const REFRESH_TIME = 60 * 15 // 15 minutes

  const isSearchLoaded = computed<boolean>(() => {
    return searchCatalogIndex.isIndexLoaded && miniSearch ? true : false
  })

  interface GetRefinerTag {
    _id: cat_ITag['_id']
    name: cat_ITag['name']
    type: cat_ITag['type']
    parent_id?: cat_ITag['parent_id']
  }

  async function initStore() {
    // console.info('initStore')
    // try / catch needed to avoid pre-rendering error in nuxt build on github actions
    try {
      // load search index data
      await searchCatalogIndex.loadIndex()
      const r = searchCatalogIndex.index
      if (searchCatalogIndex.index && searchCatalogIndex.index.length) {
        miniSearch = new MiniSearch<SearchIndexItem>(minisearchOptions)
        miniSearch.addAll(searchCatalogIndex.index) // load and process index
        // load tag refiner data
        let tag_ids = [
          ...searchCatalogIndex.index.map((result) => result.ti).flat(),
          ...searchCatalogIndex.index.map((result) => result.pti).flat(),
        ]
        tag_ids = [...new Set(tag_ids)] // remove duplicates

        const _tags: GetRefinerTag[] = await $trpc.cat_Search.getRefinerTags.mutate(tag_ids)
        if (_tags) tags.value = _tags.sort((a, b) => a.name.localeCompare(b.name)) as RefinerTag[]
        // load pack refiner data
        let pack_ids = searchCatalogIndex.index.map((result) => result.pi).flat()
        pack_ids = [...new Set(pack_ids)] // remove duplicates
        const _packs = await $trpc.cat_Search.getRefinerPacks.mutate(pack_ids)
        if (_packs) packs.value = _packs
        // console.info('index loaded')
      } else {
        console.info('search index not found')
      }
    } catch (error: unknown) {
      console.info('search index failed to load')
    }
  }

  async function refreshStore() {
    if (Math.floor(Date.now() / 1000) >= currentTime.value + REFRESH_TIME || !miniSearch) {
      await searchCatalogIndex.refreshIndex()
      await initStore()
      currentTime.value = Math.floor(Date.now() / 1000)
    }
  }

  // tag types
  const tagTypes = computed<RefinerTag['type'][]>(() => {
    const _types: RefinerTag['type'][] = []
    switch (mode.value) {
      case 'site':
        _types.push('cat-category')
        _types.push('cat-mood')
        _types.push('cat-style')
        _types.push('cat-attribute')
        break
      case 'instruments':
        _types.push('cat-category')
        _types.push('cat-mood')
        _types.push('cat-style')
        _types.push('cat-attribute')
        break
      case 'drumkits':
        _types.push('cat-style')
        _types.push('cat-mood')
        _types.push('cat-attribute')
        break
      // case 'loops':
      //   break
    }
    return _types
  })

  /**
   * Tag helpers
   */
  const getTagsByType = (type: string) => tags.value.filter((e) => e.type === type)
  const getTagParentsByType = (type: string) =>
    tags.value.filter((e) => e.type === type && typeof e.parent_id === 'undefined')
  const getTagChildrenByParentId = (parent: RefinerTag) =>
    tags.value.filter(
      (e) =>
        e.type === parent.type && typeof e.parent_id !== 'undefined' && e.parent_id === parent._id,
    )
  const getTagById = (id: string) => tags.value.find((e) => e._id.toString() === id)

  /**
   * Pack helpers
   */
  function getPackById(id: string) {
    const matches = packs.value.filter((obj) => obj._id.toString() === id)
    return matches.length === 1 ? matches[0] : null
  }

  /**
   * Search
   */
  const terms = ref<string | undefined>()
  const searchInstrumentsResults = ref<PopulatedInstrument[]>([])
  const indexResult = ref(0)
  const totalResult = ref(0)
  const currentPage = ref(1)
  const totalPages = computed(() => {
    return Math.ceil(totalResult.value / ITEMS_PER_PAGE)
  })

  function cleanPaginatedSearchResults() {
    indexResult.value = 0
    currentPage.value = 1
  }

  async function search() {
    // populate ids to be retrieved
    let ids: string[] = []
    if (miniSearch && terms.value && terms.value.length >= SEARCH_TEXT_MIN_LENGTH) {
      const miniSearchResults = miniSearch.search(terms.value, {
        filter: (result) => filterByRefiners(result),
      })
      ids = miniSearchResults.map((result) => result.id)
    } else if (searchCatalogIndex.index) {
      ids = searchCatalogIndex.index
        .filter((result) => filterByRefiners(result))
        .map((result) => result.id)
    }
    if (ids.length) {
      const i18n = useNuxtApp().$i18n as any
      const locale = i18n.locale as WritableComputedRef<string>
      const opts: trpcRouterInput['cat_Instruments']['getSearchResultInstruments'] = {
        ids,
        language: locale.value,
        searchTerms: terms.value,
        relatedVideos: options.relatedVideos,
      }
      // pagination on for 'site' mode
      if (mode.value === 'site') {
        opts.index = indexResult.value
        opts.count = ITEMS_PER_PAGE
      }
      const data = await $trpc.cat_Instruments.getSearchResultInstruments.mutate(opts)
      if (data) {
        searchInstrumentsResults.value = data.items
        totalResult.value = data.total
      }
    } else {
      searchInstrumentsResults.value = []
    }
  }

  /**
   * Refiners
   */
  const refiners = ref<SearchCatalogSelectedRefiners>({
    category: null,
    styles: [],
    attributes: [],
    moods: [],
    pack: null,
  })

  const isRefinersSelectionEmpty = computed<boolean>(() => {
    return (
      !refiners.value.category &&
      !refiners.value.styles.length &&
      !refiners.value.attributes.length &&
      !refiners.value.moods.length &&
      !refiners.value.pack
    )
  })

  /**
   * Price range
   */
  const priceRangeList = computed(() => getSelectPriceRanges())
  const priceRangeValue = ref<string>(priceRangeList.value[0].value)
  const priceRange = computed(() => {
    const found = priceRangeList.value.find((e) => e.value === priceRangeValue.value)
    return found ? found : priceRangeList.value[0]
  })

  // returns a list of refiners items filtered by user role
  const refinerList = computed<RefinerList>(() => {
    const r = {} as RefinerList
    tagTypes.value.forEach((type) => {
      if (type === 'cat-category') {
        r.categories = getTagsByType('cat-category')
      } else {
        let list: RefinerTag[] = []
        const parents = getTagParentsByType(type)
        parents.forEach((parent) => {
          list.push(parent)
          list = list.concat(getTagChildrenByParentId(parent))
        })
        if (type === 'cat-style') r.styles = list
        if (type === 'cat-attribute' && userStore.isAdminRole) r.attributes = list
        if (type === 'cat-mood') r.moods = list
      }
    })
    if (packs.value.length && userStore.isAdminRole) {
      r.packs = packs.value
    }
    return r
  })

  function filterByRefiners(result: SearchResult | SearchIndexItem) {
    function filterByCategory(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (typeof refiners.value.category === 'string') {
        if (!result.ti.includes(refiners.value.category)) includeResult = false
      }
      return includeResult
    }
    function filterByStyles(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (refiners.value.styles.length) {
        includeResult = false
        refiners.value.styles.forEach((style) => {
          if (result.ti.includes(style) || result.pti.includes(style)) includeResult = true
        })
      }
      return includeResult
    }
    function filterByAttributes(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (refiners.value.attributes.length) {
        includeResult = false
        refiners.value.attributes.forEach((attribute) => {
          if (result.ti.includes(attribute)) includeResult = true
        })
      }
      return includeResult
    }

    function filterByMoods(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (refiners.value.moods.length) {
        includeResult = false
        refiners.value.moods.forEach((mood) => {
          if (result.ti.includes(mood)) includeResult = true
        })
      }
      return includeResult
    }

    function filterByPackId(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (typeof refiners.value.pack === 'string') {
        includeResult = result.pi === refiners.value.pack
      }
      return includeResult
    }

    function filterByPriceRange(result: SearchResult | SearchIndexItem) {
      let includeResult = true
      if (typeof priceRange.value.max === 'number')
        includeResult = result.pr >= priceRange.value.min && result.pr <= priceRange.value.max
      else includeResult = result.pr >= priceRange.value.min
      return includeResult
    }

    return (
      filterByCategory(result) &&
      filterByStyles(result) &&
      filterByAttributes(result) &&
      filterByMoods(result) &&
      filterByPackId(result) &&
      filterByPriceRange(result)
    )
  }

  function cleanupRefiners() {
    switch (mode.value) {
      case 'drumkits':
        refiners.value = {
          category: DRUMKIT_TAG_ID,
          styles: [],
          attributes: [],
          moods: [],
          pack: null,
        }
        break
      default:
        refiners.value = {
          category: null,
          styles: [],
          attributes: [],
          moods: [],
          pack: null,
        }
        break
    }
  }

  const selectedRefinersItems = computed<RefinerListItem[]>(() => {
    const r: RefinerListItem[] = []
    function pushTag(id: string) {
      const tag = getTagById(id)
      if (tag) r.push(tag)
    }
    function pushPack(id: string) {
      const pack = getPackById(id)
      if (pack) r.push(pack)
    }
    if (refiners.value.category) pushTag(refiners.value.category)
    refiners.value.styles.forEach((e) => {
      pushTag(e)
    })
    refiners.value.attributes.forEach((e) => {
      pushTag(e)
    })
    refiners.value.moods.forEach((e) => {
      pushTag(e)
    })
    if (refiners.value.pack) pushPack(refiners.value.pack)
    return r
  })

  function unselectRefinersItem(item: RefinerListItem): void {
    if ('type' in item) {
      if (item.type === 'cat-category') {
        refiners.value.category = null
      }
      if (item.type === 'cat-style') {
        refiners.value.styles = refiners.value.styles.filter((e) => e !== item._id.toString())
      }
      if (item.type === 'cat-attribute') {
        refiners.value.attributes = refiners.value.attributes.filter(
          (e) => e !== item._id.toString(),
        )
      }
      if (item.type === 'cat-mood') {
        refiners.value.moods = refiners.value.moods.filter((e) => e !== item._id.toString())
      }
    } else {
      refiners.value.pack = null
    }
  }

  /**
   * Auto suggestion
   */
  const suggestions = ref<string[]>([])

  function getSuggestionsFromTerms(v: string) {
    return miniSearch
      .autoSuggest(v, suggestionOptions)
      .slice(0, MAX_SUGGESTIONS)
      .map((e) => e.suggestion)
  }

  function highlightTerms() {
    useMarkJS().unmark()
    if (terms.value && terms.value.length >= SEARCH_TEXT_MIN_LENGTH) useMarkJS().mark(terms.value)
  }

  async function updateSearch() {
    cleanPaginatedSearchResults()
    await search()
    highlightTerms()
  }

  /**
   * Mode
   */
  // 👉 update refiners on change mode
  function changeMode(v: SearchMode) {
    mode.value = v
    cleanupRefiners()
  }

  /**
   * url query params
   */
  type UrlSearchParams = {
    terms: string | undefined
    refiners: SearchCatalogSelectedRefiners
    priceRangeValue: string
  }

  function encodeUrlParam(urlSearchParams: UrlSearchParams) {
    return btoa(JSON.stringify(urlSearchParams))
  }
  function decodeUrlParam(v: string) {
    return JSON.parse(atob(v)) as UrlSearchParams
  }

  function getUrlSearchParams() {
    if (!options.useSearchQueryUrl) return
    return encodeUrlParam({
      terms: terms.value,
      refiners: refiners.value,
      priceRangeValue: priceRangeValue.value,
    })
  }

  function setUrlSearchParams(v: string) {
    if (!options.useSearchQueryUrl) return
    const params = decodeUrlParam(v)
    terms.value = params.terms
    refiners.value = params.refiners
    priceRangeValue.value = params.priceRangeValue
  }

  async function goToSearchUrl() {
    if (!options.useSearchQueryUrl) return
    if (import.meta.client) await navigateTo({ query: { q: getUrlSearchParams() } })
  }

  /**
   * Watchers
   * vue watchers : we need to use deep: true to watch ref / computed / reactive in Pinia store https://github.com/vuejs/pinia/discussions/2532
   */

  watch(
    terms,
    async (v) => {
      // suggest search queries given an incomplete query:
      if (v && miniSearch && v.length >= SEARCH_TEXT_MIN_LENGTH) {
        suggestions.value = getSuggestionsFromTerms(v)
      } else suggestions.value = []

      // reset search results if search terms are empty
      if (!v) await updateSearch()
      await goToSearchUrl()
    },
    { deep: true },
  )

  watch(
    refiners,
    async (v) => {
      await updateSearch()
      await goToSearchUrl()
    },
    { deep: true },
  )

  watch(
    priceRangeValue,
    async (v) => {
      await updateSearch()
      await goToSearchUrl()
    },
    { deep: true },
  )

  watch(
    currentPage,
    async (v) => {
      indexResult.value = (v - 1) * ITEMS_PER_PAGE
      await search()
    },
    { deep: true },
  )

  return {
    DRUMKIT_TAG_ID,
    mode,
    changeMode,

    // store objects
    tags,
    tagTypes,
    packs,
    terms,
    suggestions,
    refiners,
    refinerList,
    priceRange,
    priceRangeValue,
    priceRangeList,
    searchInstrumentsResults,
    currentPage,
    totalPages,

    // store functions
    isSearchLoaded,
    refreshStore,
    isRefinersSelectionEmpty,
    cleanupRefiners,
    selectedRefinersItems,
    unselectRefinersItem,
    getSuggestionsFromTerms,
    highlightTerms,
    updateSearch,
    getUrlSearchParams,
    setUrlSearchParams,
    getTagsByType,
    getTagParentsByType,
    getTagChildrenByParentId,
    getTagById,
    getPackById,
  }
}
