import { brotliDecode } from './brotli-decode'
// import type { BrotliDecodeOptions } from './brotliDecode'
// type brotliDecode = (bytes: Int8Array, options?: BrotliDecodeOptions) => Int8Array

function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64)
  const bytes = new Int8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

export function brotliDecompress(input: string) {
  const compressedBuffer = base64ToArrayBuffer(input)
  const uint8array = brotliDecode(compressedBuffer)
  const str = new TextDecoder().decode(uint8array)
  const json = JSON.parse(str)
  return json
}
